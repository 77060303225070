@import 'src/utils.less';
@import 'src/lib_css/page.less';

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-Thin.ttf') format('truetype');
    font-weight: 200;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), url('/assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Seldom-Scene';
    src: local('Seldom-Scene'), url('/assets/fonts/Seldom-Scene.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

* {
    -webkit-tap-highlight-color: transparent;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: Montserrat, "Helvetica Neue", sans-serif;
    color: @textColor;
    font-weight: @fontWeightMedium;
}

* {
    outline: none;
}

a {
    color: @textColor;
    text-decoration: none;
    font-weight: @fontWeightSemiBold;
    line-height: @smallLineHeight;

    &:hover,
    &:active {
        color: @colorBaseLight;
        border-bottom: 1px solid @colorBaseLight;
    }
}

.modal a {
    &:hover,
    &:active {
        border-bottom: none;
    }
}

.link {
    font-size: @baseFontSize;
    font-weight: @fontWeightMedium;
}

p {
    font-size: @contentFontSize;
    font-weight: @fontWeightMedium;
    line-height: @lineHeight;
    margin: 0;
}

.tg:before {
    content: "";
    background: url("/assets/tg.svg") center center no-repeat;
    width: 50px;
    height: 50px;
}

.email:before {
    content: "";
    background: url('/assets/email.svg') center center no-repeat;
    width: 50px;
    height: 50px;
}

@buttonTransition: background-color 0.5s, color 0.5s, fill 0.5s;

button {
    padding: 0 10px;
    border-radius: 10px;
    color: @bgColorWhite;
    background-color: @colorBase;
    border: none;
    outline: none;
    font-weight: @fontWeightSemiBold;
    cursor: pointer;
    font-size: @buttonFontSize;
    min-height: 52px;
    font-family: Montserrat, "Helvetica Neue", sans-serif;
    transition: @buttonTransition;
    height: 52px;

    &:hover {
        background-color: @colorBaseDark;
        transition: @buttonTransition;
    }
}

.button-light {
    color: @colorBase;
    background-color: @bgColorWhite;

    path {
        fill: @colorBase;
        transition: @buttonTransition;
    }

    &:hover {
        background-color: @colorBaseDark;
        color: @bgColorWhite;
        border: 0;

        path {
            fill: @bgColorWhite;
            transition: @buttonTransition;
        }
    }
}

.button-link {
    border-bottom: 0;

    &:hover {
        border-bottom: 0;
    }
}

input[type=text], input[type=email] {
    width: 100%;
}

input[type=text], input[type=email], input[type=text]::placeholder, input[type=email]::placeholder {
    line-height: @contentFontSize;
    font-size: @contentFontSize;
}

input[type=text], input[type=email] {
    background: @bgColorWhite;
    border: none;
    border-bottom: 1px solid @grayColor;
    box-sizing: border-box;
    padding: 12px 2px 12px 2px;
    margin-bottom: 10px;
    outline: none;
}

input[type=text]::placeholder, input[type=email]::placeholder {
    padding: 0;
    color: @grayColor;
}

.info-title {
    font-size: @infoTitle;
}

h1 {
    font-size: @h1FontSize;
    font-weight: @fontWeightBold;
}

h2 {
    font-size: @h2FontSize;
    font-weight: @fontWeightBold;
}

h3 {
    font-size: @h3FontSize;
    font-weight: @fontWeightBlack;
    text-transform: uppercase;
}

h4 {
    font-size: @h4FontSize;
    font-weight: @fontWeightBold;
}

h5 {
    font-size: @h4FontSize;
    font-weight: @fontWeightSemiBold;
}

.subtitle, .banner .subtitle {
    font-size: @baseFontSize;
    font-weight: @fontWeightMedium;
    line-height: @smallLineHeight;
}

.thin {
    font-weight: @fontWeightLight !important;

    h1 {
        font-weight: @fontWeightMedium;
    }

    a {
        font-weight: @fontWeightLight !important;
    }
}

div {
    font-size: @baseFontSize;
}

.text {
    line-height: @smallLineHeight;
}

.small-text {
    font-size: @middleFontSize;
}

.text-blue {
    color: @colorBase;
}

.description {
    font-size: @contentFontSize;
    color: @grayColorDark;
}

.modal {
    @innerPadding: 30px;
    padding: @innerPadding;

    .title {
        font-size: @modalTitleFontSize;
        font-weight: @fontWeightBold;
    }

    .field-title {
        font-size: @baseFontSize;
        color: @grayColor;
    }

    a {
        font-weight: @fontWeightMedium !important;
        text-decoration: underline;
    }
}

.underline {
    text-decoration: underline;
}

.underline:hover {
    border-bottom: 0;
}

.error {
    color: @errorText;
    font-size: @middleFontSize;
    min-height: @middleFontSize + 4;
}

.videoplayer {
    --plyr-color-main: @colorBase;

    .plyr__control--overlaid {
        min-height: 36px;
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        background: none;
        border-radius: 0;
        opacity: 1;

        .play-image {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            transform: scale(0.6);
        }

        .square-background {
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.3);
            border-radius: 20px;
            backdrop-filter: blur(4px);
            -webkit-backdrop-filter: -webkit-blur(3px);
        }

        svg {
            margin: 0;
            width: 100%;
            height: auto;
        }
    }

    .button-padding .play-image{
        padding: 30%;
        transform: scale(1);
    }

    .plyr__control--overlaid:hover {
        transform: scale(1.2) translateX(-42%) translateY(-42%);
        transition: transform 0.2s;
        transform-origin: center center;
    }

    .plyr--video.plyr--stopped .plyr__controls{
        opacity: 0;
    }

    .plyr__progress {
        width: 100%;
    }
}

@import 'src/lib_css/tablet-sm';
@import 'src/lib_css/tablet';
@import 'src/lib_css/desktop-sm';
@import 'src/lib_css/desktop-md';
@import 'src/lib_css/desktop-lg';
