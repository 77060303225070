@import 'src/utils.less';

.desktopSd() {
    @contentFontSize: 20px;
    @baseFontSize: 14px;
    @middleFontSize: 14px;
    @smallFontSize: 12px;
    @lineHeight: 26px;
    @smallLineHeight: 22px;

    @infoTitle: 50px;
    @h1FontSize: 50px;
    @h2FontSize: 60px;
    @h3FontSize: 40px;
    @h4FontSize: 22px;
    @subtitleFontSize: 20px;

    .subtitle, .banner .subtitle  {
        font-size: @subtitleFontSize;
        font-weight: @fontWeightMedium;
        line-height: @smallLineHeight;
    }

    .banner .subtitle {
        font-size: @subtitleFontSize;
        line-height: 26px;
    }

    .description {
        font-size: @contentFontSize !important;
    }

    .info-title {
        font-size: @infoTitle;
    }

    p {
        font-size: @contentFontSize;
        line-height: @lineHeight;
    }

    a {
        line-height: 18px;
    }

    h1 {
        font-size: @h1FontSize;
        font-weight: @fontWeightBold;
    }

    h2 {
        font-size: @h2FontSize;
        font-weight: @fontWeightBold;
    }

    h3 {
        font-size: @h3FontSize;
        font-weight: @fontWeightBlack;
    }

    h4 {
        font-size: @h4FontSize;
        font-weight: @fontWeightBold;
    }

    h5 {
        font-size: @h4FontSize;
        font-weight: @fontWeightSemiBold;
    }

    .thin {
        font-weight: @fontWeightLight;

        h1 {
            font-weight: @fontWeightMedium;
        }
    }

    .modal {
        @innerPadding: 30px;
        padding: @innerPadding;

        .title {
            font-size: @modalTitleFontSize;
        }

        .field-title {
            font-size: @subtitleFontSize;
        }
    }

    input[type=text], input[type=email], input[type=text]::placeholder, input[type=email]::placeholder {
        line-height: 16px;
        font-size: 16px;
    }

    .error {
        color: @errorText;
        font-size: @middleFontSize;
        min-height: @middleFontSize + 6;
    }

    .videoplayer .plyr__control--overlaid {
        height: 180px;
        width: 180px;

        .square-background {
            border-radius: 30px;
        }
    }
}

.desktop-sm(.desktopSd());
