@import 'src/utils.less';

.tabletSm() {

    .small-text {
        font-size: @baseFontSize;
    }

    .banner .subtitle {
        font-size: @buttonFontSize;
    }

}

.tablet-sm(.tabletSm());
