@import 'src/utils.less';

.desktopLg() {
    @contentFontSize: 22px;
    @buttonFontSize: 16px;
    @baseFontSize: 16px;
    @middleFontSize: 14px;
    @smallFontSize: 12px;
    @subtitleFontSize: 22px;
    @lineHeight: 30px;
    @smallLineHeight: 24px;

    @h1FontSize: 50px;
    @h2FontSize: 60px;
    @h3FontSize: 40px;
    @h4FontSize: 30px;

    .subtitle {
        font-size: @subtitleFontSize;
        font-weight: @fontWeightMedium;
    }

    h1 {
        font-size: @h1FontSize;
        font-weight: @fontWeightBold;
    }

    h2 {
        font-size: @h2FontSize;
        font-weight: @fontWeightBold;
    }

    h3 {
        font-size: @h3FontSize;
        font-weight: @fontWeightBlack;
    }

    h4 {
        font-size: @h4FontSize;
        font-weight: @fontWeightBold;
    }

    h5 {
        font-size: @h4FontSize;
        font-weight: @fontWeightSemiBold;
    }

    .thin {
        font-weight: @fontWeightLight;

        h1 {
            font-weight: @fontWeightMedium;
        }
    }
}

.desktop-lg(.desktopLg());
