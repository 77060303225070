@colorBase: #0041F4;
@colorBaseDark: #00299A;
@colorBaseLight: #38A1FF;
@bgColorWhite: #ffffff;
@bgColorBase: #ffffff;
@bgColorGrayHaven: #F7FBFF;
@textColor: #222222;
@grayColorDark: #747474;
@grayColor: #ACACAC;
@grayColorLight: #E3E3E3;
@widgetBgColor: #F7FBFF;

@colorGradientLight: #00B2FF;
@colorGradientBase: #0423C8;

@modalBackground: rgba(0, 0, 0, 0.6);
@orderButtonDisable: #CFD1DB;
@errorText: #D52D2A;

@contentFontSize: 14px;
@buttonFontSize: 16px;
@baseFontSize: 14px;
@middleFontSize: 12px;
@smallFontSize: 10px;
@lineHeight: 20px;
@smallLineHeight: 18px;

@infoTitle: 30px;
@h1FontSize: 20px;
@h2FontSize: 26px;
@h3FontSize: 20px;
@h4FontSize: 20px;
@modalTitleFontSize: 20px;

@fontWeightThin: 200;
@fontWeightExtraLight: 300;
@fontWeightLight: 400;
@fontWeightMedium: 500;
@fontWeightSemiBold: 600;
@fontWeightBold: 700;
@fontWeightBlack: 900;

//@content-max-width: 1750px;
@content-max-width: 1468px;
@content-padding: 30px;
@content-padding-mobile: 20px;

@desktop-lg-area: 1900px;

.desktop-lg(@rules) {
    @media (min-width: @desktop-lg-area) { @rules(); }
}

@desktop-md-area: 1500px;

.desktop-md(@rules) {
    @media (min-width: @desktop-md-area) { @rules(); }
}

@desktop-sm-area: 1100px;

.desktop-sm(@rules) {
    @media (min-width: @desktop-sm-area) { @rules(); }
}

@tablet-area: 860px;

.tablet(@rules) {
    @media (min-width: @tablet-area) { @rules(); }
}

@sm-tablet-area: 600px;

.tablet-sm(@rules) {
    @media (min-width: @sm-tablet-area) { @rules(); }
}
