@import 'src/utils.less';

.showInfo() {
    @import 'src/lib_css/tablet-utils';

    .info-title {
        font-size: @infoTitle;
    }

    p {
        font-size: @contentFontSize;
    }

    h1 {
        font-size: @h1FontSize;
    }

    h2 {
        font-size: @h1FontSize;
    }

    .subtitle  {
        font-size: @subtitleFontSize;
        line-height: @smallLineHeight;
    }

    .banner .subtitle {
        font-size: @subtitleFontSize;
        line-height: 24px;
    }

    .description {
        font-size: @contentFontSize !important;
    }

    button {
        max-width: 232px;
    }

    .text {
        line-height: @smallLineHeight;
    }

    input[type=text], input[type=email], input[type=text]::placeholder, input[type=email]::placeholder {
        line-height: @contentFontSize;
        font-size: @contentFontSize;
    }

    .videoplayer .plyr__control--overlaid {
        height: 100px;
        width: 100px;
    }

}

.tablet(.showInfo());
