.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px 50px 20px;
    box-sizing: border-box;
    margin: 0 auto;
    align-items: center;
}

.full-height {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.full-width {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: none !important;
    overflow-x: hidden;

    .section-title {
        width: 100%;
        padding-left: @content-padding;
    }
}

.article {
    display: flex;
    flex-direction: column;

    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 654px;

        p {
            margin: 10px 0 30px 0;
        }
    }

    .content-img {
        text-align: center;
        max-width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            max-width: 100%;
        }

        #dev-tools-img, #techs-img {
            max-width: 300px;
        }
    }
}

.banner-section .banner {
    h1, button {
        margin: 0;
    }
}


.section-title {
    width: 100%;
    display: flex;
    justify-content: start;
    text-transform: uppercase;
    z-index: 3;

    h2 {
        max-width: 70%;
    }
}

.req-item {

    & > div {
        margin-bottom: 20px;
        margin-top:  20px;
    }

    div:first-child {
        margin-top: 0;
    }
}

.req-item .text-blue {
    font-weight: @fontWeightSemiBold;
}

@media (min-width: 550px) {
    .article {
        img {
            max-width: 600px;
            width: 100%;
        }

        .content-img {
            max-width: 600px !important;
        }
    }
}

.subsection {
    .text{
        max-width: none !important;
        width: 100%;
    }

    .img-container {
        display: flex;
        justify-content: center;
    }
}

.overflow-hidden {
    overflow-x: hidden;
}

.showInfo() {

    .section {
        max-width: @content-max-width;
        padding: 0 30px 30px 30px;
    }

    .banner-section {
        margin: 0 auto;
        align-items: center;

        .banner {
            margin-top: 30px;
            max-width: @content-max-width;
        }
    }

    .subsection {
        margin: 0;
        width: 100%;
    }

    .article {
        justify-content: space-between;
        flex-direction: row;
        flex: 1 auto;

        .block-text {
            max-width: 50%;
            margin: 0 5vw 0 0;
        }
    }

    .reverse {
        flex-direction: row-reverse;

        .block-text {
            margin: 0 0 0 5vw;
        }
    }

    .section-title {
        width: 100%;
        text-align: center !important;
        justify-content: center;

        h2 {
            max-width: 100%;
        }
    }

    .overflow-hidden {
        overflow-x: visible;
    }
}

.desktopSd() {}
.desktopMd() {
    .section {
        box-sizing: content-box;
    }
}

.tablet(.showInfo());
.desktop-sm(.desktopSd());
.desktop-md(.desktopMd());
