@import 'src/utils.less';

.desktopMd() {
    @import 'src/lib_css/desktop-md-utils';

    body {
        font-size: @baseFontSize !important;
    }

    div {
        font-size: @baseFontSize;
    }

    .text {
        line-height: @smallLineHeight;
    }

    .description {
        font-size: @contentFontSize !important;
    }

    .middle {
        font-size: @middleFontSize !important;
        font-weight: @fontWeightMedium;

        a {
            font-size: @middleFontSize !important;
        }
    }

    .link {
        font-size: @baseFontSize;
        font-weight: @fontWeightMedium;
    }

    a {
        font-size: @baseFontSize;
        line-height: @smallLineHeight;
    }

    p {
        font-size: @contentFontSize;
        line-height: @lineHeight;
    }

    button {
        font-size: @buttonFontSize;
        font-weight: @fontWeightSemiBold;
    }

    .subtitle, .banner .subtitle  {
        font-size: @subtitleFontSize;
        line-height: @lineHeight;
    }

    .info-title {
        font-size: @infoTitle;
    }

    h1 {
        font-size: @h1FontSize !important;
        font-weight: @fontWeightBold;
        line-height: 67.5px;
    }

    h2 {
        font-size: @h2FontSize;
        font-weight: @fontWeightBold;
    }

    h3 {
        font-size: @h3FontSize;
        font-weight: @fontWeightBlack;
    }

    h4 {
        font-size: @h4FontSize;
        font-weight: @fontWeightBold;
    }

    h5 {
        font-size: @h4FontSize;
        font-weight: @fontWeightSemiBold;
    }

    .modal {
        @innerPadding: 30px;
        padding: @innerPadding;

        .title {
            font-size: @modalTitleFontSize;
        }

        .field-title {
            font-size: @subtitleFontSize;
        }

        .policy {
            font-size: @middleFontSize;

            a {
                font-size: @middleFontSize;
            }
        }
    }

    input[type=text], input[type=email], input[type=text]::placeholder, input[type=email]::placeholder {
        line-height: @contentFontSize;
        font-size: @contentFontSize;
    }

    .error {
        color: @errorText;
        font-size: @middleFontSize;
        min-height: @middleFontSize + 6;
    }

    .small-text {
        font-size: 17px;
    }

    .videoplayer .plyr__control--overlaid {
        height: 220px;
        width: 220px;
    }

}

.desktop-md(.desktopMd());
